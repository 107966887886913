

export const AnOffhireMachineryUtil = {
    groupMachineriesByType: (machineries) => {
        // debugger;
        // console.log("machineries");
        let groupedMachineries = {};
    
        (machineries instanceof Array) && machineries?.forEach((machinery) => {
          groupedMachineries[machinery.machinery_type.id] = groupedMachineries[
            machinery.machinery_type.id
          ] ?? { id: "", name: "", sfi_code: "", machineries: [] };
    
          groupedMachineries[machinery.machinery_type.id].id =
            machinery.machinery_type.id;
          groupedMachineries[machinery.machinery_type.id].name =
            machinery.machinery_type.name;
          groupedMachineries[machinery.machinery_type.id].sfi_code =
            machinery.machinery_type.sfi_code;
          groupedMachineries[machinery.machinery_type.id].machineries.push(
            machinery
          );
    });
    
    // Convert to array
    let groupedMachineryTypes = [];
    for (let key in groupedMachineries) {
        groupedMachineryTypes.push(groupedMachineries[key]);
    }
    return groupedMachineryTypes;
    },
};


export const AnOffhireEventUtil = {
    /**
   * Relation removed, so no more required form 26-Jan-2023
   * @param {*} eventTypes 
   * @returns 
   */
  extractEventsCategoriesFromEventTypes: (eventTypes) => {
    let eventsCategories = {};
    (eventTypes instanceof Array) && eventTypes?.forEach((eventType) => {
      eventsCategories[eventType.offhire_events_category.id] =
        eventType.offhire_events_category;
    });

    // Convert to array
    let eventsCategoriesArray = [];
    for (let key in eventsCategories) {
      eventsCategoriesArray.push(eventsCategories[key]);
    }
    return eventsCategoriesArray;
  },
};


export const OffhireFormValueUtil = {
  altIfEmpty: (value, altValue) => {
    return (value || value === 0) ? value : altValue;
  },
}