import AnCommonFormDialog from "../../core/components/forms/AnCommonFormDialog";

function VesselForm(props){
     
    const labels = {
        fleet: {
          isGroupTitle: false,
          title: "Fleet",
          type: "select",
          options: [
            {
              value: "",
              title: "-Select Fleet-",
            },
          ],
          optionsFetch:{
            api:  "/fleet/",
            responseHandler: (o)=>o?.map((a)=>({value:a.id, title:a.fleet_name})),
          }
        },
        ship_manager: {
          isGroupTitle: false,
          title: "Ship Manager",
          type: "select",
          options: [
            {
              value: "",
              title: "-Select Ship Manager-",
            },
          ],
          optionsFetch:{
            // api:  "/person/shipmanager",
            api:  "/users/all",
            responseHandler: (o)=>o?.filter((a)=>a?.role?.name==='shipmanager')
            .map((a)=>({value:a.id, title:a.username})),
          },
          required: false,
        },
        vessel_name: {
          isGroupTitle: false,
          title: "Vessel Name",
          type: "text",
          value: "",
        },
        vessel_imo: {
          isGroupTitle: false,
          title: "Vessel IMO",
          type: "text",
          value: "",
        },
        vessel_code: {
          isGroupTitle: false,
          title: "Vessel Code",
          type: "text",
          value: "",
        },
        managed_by: {
          isGroupTitle: false,
          title: "Managed By",
          type: "select",
          options: [
            {
              value: "",
              title: "-Select Managed By-",
            },
            {
              value: 'commercial',
              title: "Commercial",
            },
            {
              value: 'technical',
              title: "Technical",
            },
          ],
        },
        vessel_flag: {
          isGroupTitle: false,
          title: "Vessel Flag",
          type: "text",
          value: "",
          required: false,
        },
        vessel_type: {
          isGroupTitle: false,
          title: "Vessel Type",
          type: "text",
          value: "",
          required: false,
        },
        vessel_year_built: {
          isGroupTitle: false,
          title: "Vessel Year Built",
          type: "number",
          value: "",
          required: false,
        },
        vessel_builder: {
          isGroupTitle: false,
          title: "Vessel Builder",
          type: "text",
          value: "",
          required: false,
        },
        vessel_owner: {
          isGroupTitle: false,
          title: "Vessel Owner",
          type: "text",
          value: "",
          required: false,
        },
      };

      const getFieldsMap = () => {
        // front:back
        return {
            fleet: "fleet_id",
            ship_manager: "ship_manager_id",
            vessel_name: "vessel_name",
            vessel_code: "vessel_code",
            vessel_imo: "vessel_imo",
            vessel_flag: "vessel_flag",
            vessel_type: "vessel_type",
            vessel_year_built: "vessel_year_built",
            vessel_builder: "vessel_builder",
            vessel_owner: "vessel_owner",
            managed_by: "managed_by",
        };
      };

     return (
        <AnCommonFormDialog
            {...props}
            header="Add Vessel"
            formFields={labels}
            formFieldsMap={getFieldsMap}
            createAPI="/vessel/"
            updateAPI="/vessel/"
        />
     )
    
}

export default VesselForm;